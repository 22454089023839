import { FC, useCallback, useMemo } from 'react';
import { ContextMenu } from 'components';
import { useStoreActions, useStoreState } from 'state';
import { getInvoiceDetailsLink, getPrebookPageLink } from 'utils/links';
import { isCurrencyEnabledForBuying } from 'utils/currencies';
import {
  useCreatePrebooksPermissionsCheck,
  useUpdateSettingsRiskPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';
import {
  CASHFLOW_TYPE_NEW,
  ICashflowFromSearch,
  updateCustomCashflow,
} from 'services/cashflows';
import {
  updatePurchaseOrSalesOrder,
  updateRateContract,
} from 'services/firebase';
import { updateExternalHedge } from 'services/firebase/externalHedges';
import useUrlValues from 'hooks/useUrlValues';
import { cleanCashflowFromElasticId } from './utils';

interface IOwnProps {
  record: ICashflowFromSearch;
  updateInMemoryCashflows: (
    updateFunction: (cashflows: ICashflowFromSearch[]) => ICashflowFromSearch[]
  ) => void;
}

const ThreeDotsMenu: FC<IOwnProps> = ({ record, updateInMemoryCashflows }) => {
  const { setUrlValue } = useUrlValues();
  const { currencies } = useStoreState((state) => state.CurrenciesState);
  const { updateInvoice } = useStoreActions((actions) => actions.InvoicesState);
  const hasCreatePrebooksPermission = useCreatePrebooksPermissionsCheck();
  const hasUpdateSettingsRiskPermissions = useUpdateSettingsRiskPermissionsCheck();

  // TODO: consider moving this logic to the BE
  const excludeCashflowFromRisk = useCallback(
    async (item: ICashflowFromSearch) => {
      const { type } = item;
      const payload = {
        id: cleanCashflowFromElasticId(item.id),
        data: {
          excludeFromRisk: !item.excludeFromRisk,
        },
      };

      if (
        type === CASHFLOW_TYPE_NEW.payableInvoice ||
        type === CASHFLOW_TYPE_NEW.receivableInvoice
      ) {
        await updateInvoice(payload);
      }

      if (
        type === CASHFLOW_TYPE_NEW.purchaseOrder ||
        type === CASHFLOW_TYPE_NEW.salesOrder
      ) {
        await updatePurchaseOrSalesOrder(payload);
      }

      if (type === CASHFLOW_TYPE_NEW.hedge) {
        await updateRateContract(payload);
      }

      if (type === CASHFLOW_TYPE_NEW.externalHedge) {
        await updateExternalHedge(payload);
      }

      if (type === CASHFLOW_TYPE_NEW.custom) {
        await updateCustomCashflow(payload);
      }
    },
    [updateInvoice]
  );

  const dropdownItems = useMemo(() => {
    const { currency, id, type, contractId, amount, dueDate, excludeFromRisk } = record;
    const recordIdClean = cleanCashflowFromElasticId(id);
    const isInvoiceCurrencyEnabledForBuying = isCurrencyEnabledForBuying({
      currencyCode: record.currency,
      currencies,
    });

    const dropdownItemsToReturn: {
      id: string;
      title: string;
      onClick?: () => void;
      link?: string;
    }[] = [];

    if (
      type === CASHFLOW_TYPE_NEW.payableInvoice ||
      type === CASHFLOW_TYPE_NEW.receivableInvoice
    ) {
      dropdownItemsToReturn.push({
        id: 'details',
        title: 'Details',
        link: getInvoiceDetailsLink({
          invoiceId: recordIdClean,
        }),
      });
    }

    if (type === CASHFLOW_TYPE_NEW.hedge) {
      dropdownItemsToReturn.push({
        id: 'details',
        title: 'Details',
        onClick: () =>
          setUrlValue({
            contractId: recordIdClean,
          }),
      });
    }

    if (type === CASHFLOW_TYPE_NEW.externalHedge) {
      dropdownItemsToReturn.push({
        id: 'details',
        title: 'Details',
        onClick: () =>
          setUrlValue({
            hedgeId: recordIdClean,
          }),
      });
    }

    if (
      (type === CASHFLOW_TYPE_NEW.payableInvoice ||
        type === CASHFLOW_TYPE_NEW.receivableInvoice) &&
      !contractId &&
      isInvoiceCurrencyEnabledForBuying &&
      hasCreatePrebooksPermission
    ) {
      dropdownItemsToReturn.push({
        id: 'prebook',
        title: 'Prebook',
        link: getPrebookPageLink({
          invoiceId: recordIdClean,
          predefinedBuyAmount: String(amount),
          predefinedDate: dueDate,
          predefinedBuyCurrency: currency,
        }),
      });
    }

    if (hasUpdateSettingsRiskPermissions) {
      dropdownItemsToReturn.push({
        id: 'toggle-exclude-from-risks',
        title: excludeFromRisk ? 'Add to Risk' : 'Remove from Risk',
        onClick: () => {
          excludeCashflowFromRisk(record).then(() =>
            updateInMemoryCashflows((existingCashflows) =>
              existingCashflows.map((existingCashflow) => {
                if (existingCashflow.id === id) {
                  return {
                    ...existingCashflow,
                    excludeFromRisk: !excludeFromRisk,
                  };
                }

                return existingCashflow;
              })
            )
          );
        },
      });
    }

    return dropdownItemsToReturn;
  }, [
    currencies,
    excludeCashflowFromRisk,
    hasCreatePrebooksPermission,
    hasUpdateSettingsRiskPermissions,
    record,
    setUrlValue,
    updateInMemoryCashflows,
  ]);

  return (
    <>
      <ContextMenu list={dropdownItems} strategy="fixed" portal />
    </>
  );
};

export default ThreeDotsMenu;
