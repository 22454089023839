import styled from 'styled-components';
import { Paragraph } from 'components/shared/Typography/Typography';

export const RedHint = styled(Paragraph)`
  color: ${({ theme }) => theme.color.red};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    text-align: right;
  }
`;
