import {
  Col,
  InlineLoader,
  Paragraph,
  Row,
  Loader,
  StaleNotification,
  StaleSwitch,
  Table,
  PermissionsChecker,
  Popup,
  Title,
} from 'components';
import Button from 'components/shared/Button/Button';
import Field from 'components/shared/Field/Field.styles';
import InputDateUncontrolled from 'components/shared/InputDateUncontrolled/InputDateUncontrolled';
import StaleReAuthenticate from 'components/shared/StaleReAuthenticate/StaleReAuthenticate';
import TooManyTimePasswordAttemptsPopup from 'components/shared/TooManyTimePasswordAttemptsPopup/TooManyTimePasswordAttemptsPopup';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';
import dayjs from 'dayjs';
import { useReAuthenticate } from 'hooks';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Firebase } from 'services';
import {
  confirmPaymentRun,
  updatePaymentRun,
  updatePaymentRunItemSummary,
} from 'services/paymentRuns';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { IPaymentRun, IPaymentRunItemSummary } from 'types/paymentRuns';
import { getFirstValidDay } from 'utils/dates';
import { errorHandler } from 'utils/errors';
import { DATE_FORMAT, DB_DATE_FORMAT, ERROR_MESSAGES } from 'variables';
import { generateAuthoriseTableColumns } from '../../tableColumnsGenerator';
import { RedHint } from './AuthoriseStep.styles';

interface OwnProps {
  paymentRun: IPaymentRun;
  setPaymentRun: Dispatch<SetStateAction<IPaymentRun | undefined>>;
  onContinue: () => void;
}

const AuthoriseStep: FC<OwnProps> = ({
  paymentRun,
  setPaymentRun,
  onContinue,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBuyFxAll, setIsLoadingBuyFxAll] = useState(false);
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [nonTradingDays, setNonTradingDays] = useState<string[]>([]);

  const {
    onReAuthenticateClick,
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  } = useReAuthenticate();

  const paymentRunId = paymentRun.id;
  const initialDate = paymentRun.instructions.paymentDate ?? '';
  const paymentRunError = paymentRun.error;
  const data = useMemo(() => paymentRun.paymentRunItemSummary || [], [
    paymentRun,
  ]);
  const nonTradingDaysToUse = new Set(nonTradingDays);
  const canSelectBuyFx = data.some(({ canChooseBuyFx }) => canChooseBuyFx);

  const { control, errors, handleSubmit, watch } = useForm<{
    password: string;
    date: string;
  }>({
    defaultValues: {
      date: initialDate ? dayjs(initialDate).format(DATE_FORMAT) : '',
    },
  });
  const date = watch('date');

  useEffect(() => {
    const getNonTradingDays = async () => {
      const detectedCurrencies = new Set<string>();

      data.forEach(({ invoicesCurrency }) =>
        detectedCurrencies.add(invoicesCurrency)
      );

      const bulkNonTradingDaysResponse = await Promise.all(
        Array.from(detectedCurrencies).map((currencyCode) =>
          Firebase.getNonTradingDays({
            ccyPair: `${currencyCode}${entityCurrencyCode}`,
          })
        )
      );

      bulkNonTradingDaysResponse.forEach((response) => {
        if (response?.success && response?.data) {
          setNonTradingDays((prevState) => [...prevState, ...response.data]);
        }
      });
    };

    getNonTradingDays();
  }, [data, entityCurrencyCode]);

  // calender props
  const minDate = getFirstValidDay(
    new Date(),
    /** after noon do not allow select today's date */
    dayjs().hour() > 12 ? 1 : 0,
    Array.from(nonTradingDaysToUse)
  );
  const defaultActiveStartDate = initialDate
    ? dayjs(initialDate).toDate()
    : dayjs().add(7, 'days').toDate();

  useEffect(() => {
    if (
      dayjs(date, DATE_FORMAT).format(DB_DATE_FORMAT) !== initialDate &&
      dayjs(date, DATE_FORMAT).isValid()
    ) {
      const updatePaymentRunPaymentDate = async () => {
        try {
          setIsLoading(true);
          const { data: response } = await updatePaymentRun({
            paymentRunId,
            paymentDate: dayjs(date, DATE_FORMAT).format(DB_DATE_FORMAT),
          });

          if (response.data) {
            setPaymentRun(response.data);
          }
        } catch (error: any) {
          errorHandler(error);
        } finally {
          setIsLoading(false);
        }
      };

      updatePaymentRunPaymentDate();
    }
  }, [date, initialDate, paymentRunId, setPaymentRun]);

  const onEdit = useCallback(
    async (recordId: string, updatedData: IPaymentRunItemSummary) => {
      try {
        setIsLoading(true);
        const { data: response } = await updatePaymentRunItemSummary({
          paymentRunId,
          summaryItemId: recordId,
          buyFx: updatedData.buyFx,
        });

        if (response.data) {
          setPaymentRun(response.data);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [paymentRunId, setPaymentRun]
  );

  const onBuyFxAll = async () => {
    try {
      const currentValue = !data.some(
        (item) => item.canChooseBuyFx && !item.buyFx
      );

      setIsLoadingBuyFxAll(true);
      const { data: response } = await updatePaymentRunItemSummary({
        paymentRunId,
        summaryItemId: 'all',
        buyFx: !currentValue,
      });

      if (response.data) {
        setPaymentRun(response.data);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoadingBuyFxAll(false);
    }
  };

  const isBuyFxOn = !data.some((item) => item.canChooseBuyFx && !item.buyFx);

  const tableColumns = useMemo(
    () =>
      generateAuthoriseTableColumns({
        // @ts-expect-error TS(2322) FIXME: Type '(recordId: string, updatedData: IPaymentRunI... Remove this comment to see the full error message
        onEdit,
        currencyByCode,
        entityDefaultCurrencyCode: entityCurrencyCode,
        isLoadingBuyFxAll,
        paymentDate: date,
      }),
    [currencyByCode, date, entityCurrencyCode, isLoadingBuyFxAll, onEdit]
  );

  const onSubmit = async (values: any) => {
    try {
      setIsLoadingSubmission(true);

      await onReAuthenticateClick(values);

      await confirmPaymentRun({
        paymentRunId,
        paymentRunTotals: paymentRun.paymentRunTotals,
      });

      onContinue();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoadingSubmission(false);
    }
  };

  return (
    <>
      <Col style={{ position: 'relative' }}>
        {(isLoading || isLoadingBuyFxAll || isLoadingSubmission) && (
          <Loader
            withBackdrop
            size="large"
            style={{ position: 'absolute', inset: 0, zIndex: 3 }}
          />
        )}
        <Table<IPaymentRunItemSummary>
          data={data}
          columns={tableColumns}
          isRowDisabled={(record) => !record.valid}
          defaultRowHeight={56}
          renderFooterContent={
            <Col>
              {paymentRunError && (
                <Row>
                  <StaleNotification
                    title="Issue with the transfers"
                    cross={false}
                    bgColor={theme.color.red}
                    style={{ maxWidth: 'unset' }}
                  >
                    {paymentRunError}
                  </StaleNotification>
                </Row>
              )}
              <Row gap={theme.spacing.m} justifyContent="space-between">
                <form
                  id="payment-run-authorise-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Col alignItems="flex-start">
                    <Row mb>
                      <Field mr flexDirection="column">
                        <Controller
                          id="date"
                          name="date"
                          control={control}
                          rules={{
                            required: ERROR_MESSAGES.requiredField,
                          }}
                          error={errors?.date?.message}
                          render={({ onChange, value, name }) => {
                            return (
                              <InputDateUncontrolled
                                id={name}
                                view="moving"
                                label="Date to schedule payment"
                                error={errors?.date?.message}
                                value={value}
                                calendarProps={{
                                  defaultActiveStartDate,
                                  minDate,
                                  minDetail: 'month',
                                }}
                                onChange={onChange}
                                disabledDates={Array.from(nonTradingDaysToUse)}
                              />
                            );
                          }}
                        />
                      </Field>

                      <PermissionsChecker action="create" resource="prebooks">
                        {canSelectBuyFx && (
                          <Row>
                            {isLoading || isLoadingBuyFxAll ? (
                              <Row style={{ width: 46 }}>
                                <InlineLoader height="24px" />
                              </Row>
                            ) : (
                              <StaleSwitch
                                id="all-payment-run-summary-items"
                                isOn={isBuyFxOn}
                                handleToggle={onBuyFxAll}
                              />
                            )}
                            <Paragraph ml>
                              Book required currency conversions
                            </Paragraph>
                          </Row>
                        )}
                      </PermissionsChecker>
                    </Row>

                    {!!paymentRun.valid ? (
                      <Button
                        type="button"
                        ml
                        onClick={() => setShowConfirmPopup(true)}
                      >
                        Confirm
                      </Button>
                    ) : (
                      <Button onClick={history.goBack} type="button">
                        Back to review
                      </Button>
                    )}
                  </Col>
                </form>

                <Col
                  gap={theme.spacing.xs}
                  alignItems="flex-end"
                  justifyContent="space-between"
                  alignSelf="stretch"
                >
                  <InvoicesCurrencyTotals
                    title="Funds required:"
                    currencyTotals={paymentRun.paymentRunTotals}
                  />

                  <RedHint>
                    You can purchase FX now or anytime prior to the payment date
                  </RedHint>
                </Col>
              </Row>
            </Col>
          }
          sortable
        />
      </Col>
      {isTooManyPasswordAttemptsError && (
        <TooManyTimePasswordAttemptsPopup
          onClose={() => setIsTooManyPasswordAttemptsError(false)}
        />
      )}
      {showConfirmPopup && (
        <Popup
          height={theme.popupHeights.xs}
          onClose={() => setShowConfirmPopup(false)}
          HeaderContent={<Title variant="h5">Confirm payment run</Title>}
          FooterContent={
            <Row gap={theme.spacing.m}>
              <StaleReAuthenticate
                control={control}
                errors={errors}
                withLabel={false}
              >
                <Button
                  type="submit"
                  form="payment-run-authorise-form"
                  isLoading={isLoadingSubmission}
                  disabled={isLoadingSubmission}
                >
                  Authorize payments
                </Button>
              </StaleReAuthenticate>

              <Button
                variant="secondary"
                onClick={() => setShowConfirmPopup(false)}
              >
                Cancel
              </Button>
            </Row>
          }
        >
          <Paragraph>
            Payment run scheduled payment date is <b>{date}</b>. Do you want to
            proceed?
          </Paragraph>
        </Popup>
      )}
    </>
  );
};

export default AuthoriseStep;
