import {
  Icon,
  Overflow,
  Paragraph,
  ParagraphWithEllipsis,
  Row,
  Title,
} from 'components';
import Card from 'components/shared/Card/Card.styles';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { BalanceWrapper } from './MainBalances.styles';
import { isExternalBalanceTypeGuard } from 'types/externalBalances';
import { ICashNeedsBffResponse } from 'types';
import { FC, useState } from 'react';
import { StyledButton } from 'components/shared/Button/Button.styles';
import MainBalancesGraph from '../MainBalancesGraph/MainBalancesGraph';

interface IOwnProps {
  allBalancesToUse: ICashNeedsBffResponse['allBalancesToUse'];
  currencyCode: string;
}

const MainBalances: FC<IOwnProps> = ({ allBalancesToUse, currencyCode }) => {
  const theme = useTheme();
  const currencyByCode = useStoreState(
    ({ CurrenciesState }) => CurrenciesState.currencyByCode
  );
  const [showGraph, setShowGraph] = useState(true);

  return (
    <Card flex={1} alignSelf="stretch" padding={`${theme.spacing.m} 0 0`}>
      <Row
        ml
        mr
        mb
        mlValue={theme.spacing.m}
        mrValue={theme.spacing.m}
        mbValue={showGraph ? theme.spacing.xs : theme.spacing.l}
      >
        <Title variant="h5">Main Balances</Title>

        <Row>
          <StyledButton
            variant="link"
            onClick={() => setShowGraph((prev) => !prev)}
          >
            <Icon
              mr
              width="20px"
              height="20px"
              fill={theme.color.greyDark}
              icon={showGraph ? 'view-lines' : 'view-graph'}
            />
          </StyledButton>
        </Row>
      </Row>

      {!showGraph && (
        <Overflow maxHeight="240px">
          {allBalancesToUse.map((balance) => (
            <BalanceWrapper key={balance.id}>
              <ParagraphWithEllipsis maxWidth="60%">
                {isExternalBalanceTypeGuard(balance)
                  ? balance.accountName
                  : `HedgeFlows ${balance.currency}`}
              </ParagraphWithEllipsis>
              <Paragraph>
                {parseIntoCurrencyStringWithSymbol(
                  balance.amount,
                  currencyByCode(balance.currency)?.symbol
                )}
              </Paragraph>
            </BalanceWrapper>
          ))}
        </Overflow>
      )}

      {showGraph && <MainBalancesGraph accounts={allBalancesToUse} />}
    </Card>
  );
};

export default MainBalances;
