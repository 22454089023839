import { FC, useState } from 'react';
import orderBy from 'lodash.orderby';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  Col,
  PermissionsChecker,
  Row,
  Loader,
  Title,
  TransferTable,
} from 'components';
import { canUseRateContract } from 'utils';
import { useStoreState } from 'state';
import { CONTRACT_STATUS, IRateContract } from 'types';
import Popup from 'components/shared/Popup/Popup';
import { getTransfersPageLink } from 'utils/links';
import Button from '../Button/Button';
import { OverflowXWrapper } from '../OverflowXWrapper/OverflowXWrapper.styles';
import useUrlValues from 'hooks/useUrlValues';
import { useAllocatePrebooksPermissionsCheck } from 'hooks/useSpecificPermissionsCheck';
import PrebookDetails from './components/PrebookDetails/PrebookDetails';
import PaymentCredentials from './components/PaymentCredentials/PaymentCredentials';
import { StepperItem } from '../StaleStepsHorizontal/StaleStepsHorizontal.styles';
import usePrebookInner from './hooks/usePrebookInner';
import TransferInvoicesTable from '../TransferInvoicesTable/TransferInvoicesTable';
import { MAX_EXTENDABLE_AMOUNT } from './consts';
import { callExternalApiWithLoading } from 'utils/fetchers';
import { moveContractExpiryDate } from 'services/firebase';
import PopupContactSupport from './components/PopupContactSupport/PopupContactSupport';
import { errorHandler } from 'utils/errors';

const PrebookInner: FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const { contractId, setUrlValue } = useUrlValues('contractId');
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const hasAllocatePrebooksPermission = useAllocatePrebooksPermissionsCheck();
  const selectedRateContract = rateContractById(contractId);
  const [isExtendingPrebookDate, setIsExtendingPrebookDate] = useState(false);
  const [showContactSupportPopup, setShowContactSupportPopup] = useState(false);
  const {
    contentType,
    setContentType,
    relatedInvoices,
    relatedTransfers,
    tabs,
    isLoadingInvoices,
  } = usePrebookInner({
    selectedRateContract,
  });
  const isAwaitingPrepayment =
    selectedRateContract?.status === CONTRACT_STATUS.awaitingPrepayment;
  const isExtendable =
    selectedRateContract &&
    selectedRateContract.status !== CONTRACT_STATUS.expired &&
    selectedRateContract.status !== CONTRACT_STATUS.used &&
    selectedRateContract.status !== CONTRACT_STATUS.cancelled;
  const isExtendedAlready = !!selectedRateContract?.conversionDateExtensions
    ?.length;
  const isRemainingAmountExtendable =
    selectedRateContract &&
    selectedRateContract.remainingTotalAmount <= MAX_EXTENDABLE_AMOUNT;
  const canExtendRightAway = !isExtendedAlready && isRemainingAmountExtendable;

  const onCancelContract = (contract: IRateContract) =>
    setUrlValue({ cancelContractId: contract.id });

  const onDeleteContract = (contract: IRateContract) =>
    setUrlValue({ deleteContractId: contract.id });

  const onClose = () => setUrlValue({ contractId: '' });

  const onExtend = async () => {
    if (canExtendRightAway) {
      return callExternalApiWithLoading({
        externalApiCall: () => moveContractExpiryDate(selectedRateContract.id),
        loadingHandler: setIsExtendingPrebookDate,
        errorHandler,
      });
    }

    setShowContactSupportPopup(true);
  };

  if (!selectedRateContract) {
    return null;
  }

  return (
    <>
      <Popup
        HeaderContent={<Title variant="h3">Prebooked Exchange</Title>}
        FooterContent={
          <Row
            gap={theme.spacing.m}
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            <Button onClick={onClose}>Close</Button>
            {isExtendable && (
              <Button
                isLoading={isExtendingPrebookDate}
                disabled={isExtendingPrebookDate}
                onClick={onExtend}
              >
                {canExtendRightAway
                  ? 'Extend by 1 month'
                  : 'Extend expiry date'}
              </Button>
            )}
            {isAwaitingPrepayment && selectedRateContract && (
              <Button
                variant="secondary"
                onClick={() => onDeleteContract(selectedRateContract)}
              >
                Cancel
              </Button>
            )}
            {selectedRateContract &&
              canUseRateContract({
                rateContract: selectedRateContract,
                hasAllocatePrebooksPermission,
              }) && (
                <>
                  <PermissionsChecker action="create" resource="transfers">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();

                        const { remainingBuyAmount, id } = selectedRateContract;

                        history.push(
                          getTransfersPageLink({
                            predefinedBuyAmount: remainingBuyAmount.toString(),
                            predefinedRateContractId: id,
                          })
                        );
                      }}
                    >
                      Transfer Using This Rate
                    </Button>
                  </PermissionsChecker>

                  <Button
                    variant="secondary"
                    onClick={() => onCancelContract(selectedRateContract)}
                  >
                    Cancel Remaining Amount
                  </Button>
                </>
              )}
          </Row>
        }
        width="900px"
        height="800px"
        onClose={onClose}
      >
        <Col rowGap={theme.spacing.xl}>
          <PrebookDetails
            selectedRateContract={selectedRateContract}
            isAwaitingPrepayment={isAwaitingPrepayment}
          />

          {isAwaitingPrepayment && (
            <PaymentCredentials selectedRateContract={selectedRateContract} />
          )}

          {isLoadingInvoices && <Loader size="large" />}

          {!isLoadingInvoices && !isAwaitingPrepayment && (
            <Col mt rowGap={theme.spacing.xl}>
              <Row justifyContent="flex-start">
                {tabs.map(({ title, id }) => (
                  <StepperItem
                    onClick={() => setContentType(id)}
                    key={id}
                    current={id === contentType}
                  >
                    {title}
                  </StepperItem>
                ))}
              </Row>

              {contentType === 'transfers' && (
                <OverflowXWrapper>
                  <TransferTable
                    data={orderBy(relatedTransfers, '_created', 'desc')}
                  />
                </OverflowXWrapper>
              )}

              {contentType === 'invoices' && (
                <OverflowXWrapper>
                  <TransferInvoicesTable
                    data={orderBy(relatedInvoices, 'status', 'asc')}
                  />
                </OverflowXWrapper>
              )}
            </Col>
          )}
        </Col>
      </Popup>

      {showContactSupportPopup && (
        <PopupContactSupport
          onClose={() => setShowContactSupportPopup(false)}
        />
      )}
    </>
  );
};

export default PrebookInner;
