import { FC, useEffect, useState } from 'react';
import { FundingAccountCredentials, Subtitle } from 'components';
import ExchangeSummary from '../ExchangeSummary/ExchangeSummary';
import { StepsNotification } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { CONVERSION_STATUS, IConversion } from 'types/conversions';
import StaleInfoBox from 'components/shared/StaleInfoBox/StaleInfoBox';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import { useStoreState } from 'state';
import { DAY_MONTH_DATE_FORMAT } from 'variables';
import { subscribeToConversionById } from 'services/firebase/conversions';
import useFundingAccountCredentialsActions from 'components/shared/FundingAccountCredentials/useFundingAccountCredentialsActions';
import FundingAccountCredentialsActions from 'components/shared/FundingAccountCredentialsActions/FundingAccountCredentialsActions';
import useBalance from 'hooks/useBalance';

dayjs.extend(isToday);

interface IOwnProps {
  conversionId: string;
  onContinue: () => void;
}

const Exchange: FC<IOwnProps> = ({ onContinue, conversionId }) => {
  const [
    createdConversion,
    setCreatedConversion,
  ] = useState<IConversion | null>(null);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const fundingAccountCredentialsActions = useFundingAccountCredentialsActions();
  const { isLoading, isEnoughBalance, onPayWithBalance } = useBalance({
    sellCurrency: createdConversion?.sellCurrency,
    sellAmount: createdConversion?.sellAmount,
  });

  useEffect(() => {
    let unsubscribe: () => void;

    if (conversionId) {
      unsubscribe = subscribeToConversionById({
        conversionId: conversionId,
        callback: (data) => setCreatedConversion(data),
      });
    }

    return () => unsubscribe?.();
  }, [conversionId]);

  if (!createdConversion) {
    return null;
  }

  const {
    conversionDate,
    sellCurrency: sellCurrencyCode,
    buyCurrency: buyCurrencyCode,
    buyAmount,
    sellAmount,
    rate,
    conversionFeeRate,
    delayedFunding,
  } = createdConversion;
  const sellCurrency = currencyByCode(sellCurrencyCode);
  const buyCurrency = currencyByCode(buyCurrencyCode);

  const isAwaitingPayment =
    createdConversion.status === CONVERSION_STATUS.awaitingPayment;

  const canBePaidWithBalance = isEnoughBalance && isAwaitingPayment;

  const showSuccessfulMessage =
    (!delayedFunding && dayjs(conversionDate).isToday()) ||
    (delayedFunding && !isAwaitingPayment);

  const onPayWithBalanceHandler = () =>
    onPayWithBalance({
      payload: {
        currency: createdConversion.sellCurrency,
        amount: createdConversion.sellAmount,
      },
    });

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          {showSuccessfulMessage && (
            <StepsNotification>
              <Subtitle color="white">
                Exchange was successful. Funds will be available in your balance
                soon.
              </Subtitle>
            </StepsNotification>
          )}

          {!delayedFunding && !dayjs(conversionDate).isToday() && (
            <StaleInfoBox
              text={
                <>
                  {`Your funds will be available on your balance on `}
                  <b>{`${dayjs(conversionDate).format(
                    DAY_MONTH_DATE_FORMAT
                  )}`}</b>
                </>
              }
            />
          )}

          {delayedFunding && isAwaitingPayment && (
            <FundingAccountCredentials
              assetId={createdConversion.id}
              canBePaidWithBalance={canBePaidWithBalance}
              sellCurrency={createdConversion.sellCurrency}
              fundingAccountCredentialsActions={
                fundingAccountCredentialsActions
              }
              status={createdConversion.status}
              isFundingInitiated={!isAwaitingPayment}
            />
          )}
        </FlowContentLeft>
        <FlowContentRight>
          <Subtitle variant="bold">Summary</Subtitle>

          {sellCurrency && buyCurrency && (
            <ExchangeSummary
              sellCurrency={sellCurrency}
              buyCurrency={buyCurrency}
              buyAmountAsNumber={buyAmount}
              sellAmountAsNumber={sellAmount}
              rate={rate}
              conversionFeeRate={conversionFeeRate}
            />
          )}
        </FlowContentRight>
      </FlowStepContent>

      <FlowStepFooter>
        <FundingAccountCredentialsActions
          isFundingInitiated={!isAwaitingPayment}
          isLoading={isLoading}
          onPayWithBalanceHandler={onPayWithBalanceHandler}
          fundingAccountCredentialsActions={fundingAccountCredentialsActions}
          onSecondaryButtonClick={onContinue}
          secondaryButtonText={
            fundingAccountCredentialsActions.isSingleFundingMethod ||
            fundingAccountCredentialsActions.isFundingManually ||
            !isAwaitingPayment
              ? 'Back to Dashboard'
              : 'Skip for now'
          }
        />
      </FlowStepFooter>
    </FlowStepWrapper>
  );
};

export default Exchange;
