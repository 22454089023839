import { IInvoiceFromSearch } from 'types';

export const updateInvoicesBasedOnBackendUpdate = (
  updatedInvoicesFromBackend: IInvoiceFromSearch[]
) => (invoices: IInvoiceFromSearch[]) => {
  return invoices.map((invoice) => {
    const updatedInvoice = updatedInvoicesFromBackend.find(
      (inv) => inv.id === invoice.id
    );

    if (updatedInvoice) {
      return updatedInvoice;
    }

    return invoice;
  });
};
