import { FC, useEffect, useState } from 'react';
import { useQuery } from 'hooks';
import { StaleCarousel, Loader, StaleRegistrationNav } from 'components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SliderWrapper } from 'pages/RiskSettings/RiskSettings.styles';
import HorizontalSteps from 'components/shared/HorizontalSteps/HorizontalSteps';
import TwoSidesTemplate from 'components/pageTemplates/TwoSidesTemplate/TwoSidesTemplate';
import { getGoToStepQuery } from 'utils';
import StepPassword from './components/StepPassword/StepPassword';
import StepInfo from './components/StepInfo/StepInfo';
import StepImport from 'components/shared/StepImport/StepImport';
import StepCurrencies from 'components/shared/StepCurrencies/StepCurrencies';
import StepHistoricalData from 'components/shared/StepHistoricalData/StepHistoricalData';
import StepAccountsFirst, {
  IOnSaveValuesArguments,
} from 'components/shared/StepAccountsFirst/StepAccountsFirst';
import StepAccountsSecondXero from 'components/shared/StepAccountsSecondXero/StepAccountsSecondXero';
import StepCongrats from 'components/shared/StepCongrats/StepCongrats';
import { ICurrency } from 'types';
import GoBack from 'components/shared/GoBack/GoBack';
import { useTheme } from 'styled-components';
import { useStoreActions } from 'state';
import { GradientTitle } from 'components/shared/GradientTitle/GradientTitle.styles';

const SignUpXero: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const URLQuery = useQuery();
  const code = URLQuery.get('code');
  const activeStep = URLQuery.get('step');
  const { url } = useRouteMatch();
  const { onSignUpFromExternalSystem } = useStoreActions(
    (state) => state.UserState
  );
  const [isLoading, setIsLoading] = useState(!code);
  const [currenciesToIgnore, setCurrenciesToIgnore] = useState<
    ICurrency['code'][]
  >([]);
  const [accountsData, setAccountsData] = useState<IOnSaveValuesArguments>();
  const activeStepAsNumber = activeStep && parseInt(activeStep);

  const goToStep = (stepNumber: number) =>
    history.push(getGoToStepQuery(url, URLQuery, stepNumber));

  useEffect(() => {
    if (!activeStep) {
      onSignUpFromExternalSystem();
    } else {
      setIsLoading(false);
    }
  }, [activeStep, onSignUpFromExternalSystem]);

  const renderContent = (step: string) => {
    switch (parseInt(step)) {
      case 1:
        return <StepPassword />;
      case 2:
        return <StepInfo />;
      case 3:
        return (
          <StepImport onContinue={() => goToStep(4)} platformName="Xero" />
        );
      case 4:
        return (
          <StepCurrencies
            onSaveValues={(values) => {
              setCurrenciesToIgnore(values);
              goToStep(5);
            }}
            platformName="Xero"
          />
        );
      case 5:
        return (
          <StepHistoricalData
            savedValues={currenciesToIgnore}
            onContinue={() => goToStep(6)}
          />
        );
      case 6:
        return (
          <StepAccountsFirst
            onSaveValues={(values) => {
              setAccountsData(values);
              goToStep(7);
            }}
            onContinue={() => goToStep(8)}
            platformName="Xero"
          />
        );
      case 7:
        return (
          <StepAccountsSecondXero
            savedValues={accountsData}
            onContinue={() => goToStep(8)}
          />
        );
      case 8:
        return <StepCongrats />;
      default:
        return null;
    }
  };

  if (isLoading || !activeStep || !activeStepAsNumber) {
    return <Loader size="large" />;
  }

  return (
    <TwoSidesTemplate
      onClose={() => history.push('/app/dashboard')}
      leftColumn={
        <>
          {activeStepAsNumber < 3 && (
            <HorizontalSteps
              activeStep={activeStepAsNumber}
              data={[
                {
                  title: 'Create a password',
                  onClick: () => goToStep(1),
                },
                {
                  title: 'Basic details',
                  onClick: () => goToStep(2),
                },
                {
                  title: 'Integration',
                  onClick: () => goToStep(3),
                },
              ]}
            />
          )}

          {activeStepAsNumber < 3 && (
            <GradientTitle variant="h1">
              Let’s sign you up to HedgeFlows
            </GradientTitle>
          )}

          {activeStepAsNumber === 8 && (
            <GradientTitle variant="h1">
              Now you’re ready to start:
            </GradientTitle>
          )}

          {activeStepAsNumber > 2 && activeStepAsNumber !== 8 && (
            <StaleRegistrationNav
              wrapperStyle={{
                justifyContent: 'flex-start',
                padding: 0,
                flex: '1 0 auto',
                maxWidth: '100%',
              }}
              title={`Your Xero integration`}
              description="Let’s configure your integration so it works as you want it to:"
              navMenu={[
                {
                  id: 1,
                  name: 'Import',
                  description: 'What data should we import?',
                  step: '01',
                },
                {
                  id: 2,
                  name: 'Currencies',
                  description: 'Which currencies should we ignore?',
                  step: '02',
                },
                {
                  id: 3,
                  name: 'Historical data',
                  description: 'How much history should we import?',
                  step: '03',
                },
                {
                  id: 4,
                  name: 'Export (if required)',
                  description: 'Which data should we export to Xero?',
                  step: '04',
                },
                {
                  id: 5,
                  name: 'Accounts (if required)',
                  description: 'Where shall we record payments?',
                  step: '05',
                },
              ]}
              activeStep={activeStepAsNumber - 2}
            />
          )}

          {(activeStepAsNumber === 8 || activeStepAsNumber < 3) && (
            <SliderWrapper>
              <StaleCarousel />
            </SliderWrapper>
          )}
        </>
      }
      rightColumn={
        activeStep && (
          <>
            {activeStepAsNumber !== 1 && activeStepAsNumber !== 8 && (
              <GoBack mb mbValue={theme.spacing.xxxl} />
            )}
            {renderContent(activeStep)}
          </>
        )
      }
    />
  );
};

export default SignUpXero;
