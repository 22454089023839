import ChartPie from 'components/shared/ChartPie/ChartPie';
import { FC } from 'react';
import { animated } from '@react-spring/web';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { IBalance, ICashNeedsBffResponse } from 'types';
import { parseIntoShortNumberWithSymbol } from 'utils';
import { CashNeedsCard } from '../CashNeedsCard/CashNeedsCard.styles';
import {
  TExternalBalance,
  isExternalBalanceTypeGuard,
} from 'types/externalBalances';
import { GRAPH_COLORS } from 'variables';

interface IOwnProps {
  accounts: ICashNeedsBffResponse['allBalancesToUse'];
}
interface IData {
  id: string;
  label: string;
  color: string;
  value: number;
  account: IBalance | TExternalBalance;
}

const getAccountNameMax12Characters = (accountName: string) =>
  accountName.length > 12 ? accountName.substring(0, 12) + '...' : accountName;

const MainBalancesGraph: FC<IOwnProps> = ({ accounts }) => {
  const theme = useTheme();
  const currencyByCode = useStoreState(
    (state) => state.CurrenciesState.currencyByCode
  );

  const data = accounts.reduce<IData[]>((acc, account, i) => {
    if (!account.amount) {
      return acc;
    }
    return [
      ...acc,
      {
        id: account.id,
        label: isExternalBalanceTypeGuard(account)
          ? getAccountNameMax12Characters(account.accountName)
          : `HF ${account.currency}`,
        value: account.amount,
        color: GRAPH_COLORS[i],
        account,
      },
    ];
  }, []);

  return (
    <CashNeedsCard
      flex={1}
      alignSelf="stretch"
      padding={theme.spacing.m}
      shadow="none"
    >
      <ChartPie<IData>
        data={data}
        colors={{ datum: 'data.color' }}
        margin={{ right: 120, bottom: 10, left: 10, top: 10 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        enableArcLinkLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={theme.color.white}
        arcLabelsComponent={({ style, datum }) => {
          const currency = currencyByCode(datum.data.account.currency);

          return (
            <animated.g
              // @ts-expect-error - TS complains, but it works
              transform={style.transform}
              style={{
                pointerEvents: 'none',
              }}
            >
              <text
                x="0"
                y="0"
                textAnchor="middle"
                dominantBaseline="central"
                fill={style.textColor}
                style={{
                  fontSize: 11,
                }}
              >
                {parseIntoShortNumberWithSymbol(
                  datum.data.account.amount,
                  currency?.symbol
                )}
              </text>
            </animated.g>
          );
        }}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 115,
            translateY: 0,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 10,
            symbolShape: 'circle',
          },
        ]}
      />
    </CashNeedsCard>
  );
};

export default MainBalancesGraph;
