import { FC } from 'react';
import { Table } from 'components';
import { IOpenBankingTableAccount } from '../../useOpenBankingSettings';
import { generateOpenBankingTableColumns } from './generateOpenBankingTableColumns';

interface IOwnProps {
  isAccountsConnected: boolean;
  data: IOpenBankingTableAccount[];
  onDisconnectAccountIntegration: (integrationId: string) => Promise<void>;
}

const OpenBankingTableTile: FC<IOwnProps> = ({
  isAccountsConnected,
  data,
  onDisconnectAccountIntegration,
}) => {
  const tableColumns = generateOpenBankingTableColumns({
    isAccountsConnected,
    onDisconnectAccountIntegration,
  });

  return (
    <Table<IOpenBankingTableAccount>
      data={data}
      columns={tableColumns}
      sortable
    />
  );
};

export default OpenBankingTableTile;
