import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { useStoreState } from 'state';
import { Notify } from 'utils';
import { packageKeysToNames, TEntityPackages } from 'state/user';
import {
  checkIsKeyInEntityPackageKeys,
  isPackageEnabled,
} from 'utils/packages';
import { InlineLoader, Paragraph, Row, StaleSwitch } from 'components';
import Button from 'components/shared/Button/Button';
import {
  WhiteFooterContainer,
  WhiteContentContainer,
} from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import { getPackages, savePackages } from 'services/firebase/entities';

const Packages = () => {
  const { entityId, user } = useStoreState(({ UserState }) => UserState);
  const [isLoadingPackages, setIsLoadingPackages] = useState(false);
  const [errorFetchingPackages, setErrorFetchingPackages] = useState(false);
  const [packages, setPackages] = useState<TEntityPackages>();

  const { control, handleSubmit, reset } = useForm<TEntityPackages>();

  useEffect(() => {
    const getPackagesFromApi = async () => {
      try {
        if (!entityId) {
          return;
        }

        setIsLoadingPackages(true);
        const packagesFromDb = await getPackages(entityId);
        if (!packagesFromDb) {
          throw new Error();
        }

        setPackages(packagesFromDb);
        reset(packagesFromDb);
      } catch (error: any) {
        Notify.error(error.message || 'Error getting existing packages');
        setErrorFetchingPackages(true);
      } finally {
        setIsLoadingPackages(false);
      }
    };

    if (entityId) {
      getPackagesFromApi();
    }
  }, [entityId, reset]);

  const onSubmit = async (values: TEntityPackages) => {
    try {
      if (!entityId) {
        return;
      }

      await savePackages(entityId, values);
      Notify.success('Successfully updated subscribed services');
    } catch (error: any) {
      Notify.error(error.message || 'Error saving packages');
    }
  };

  if (!user?.isSuperAdmin) {
    return null;
  }

  return (
    <form>
      <WhiteContentContainer>
        {errorFetchingPackages && (
          <Paragraph color="red">
            There was an error fetching your packages.
          </Paragraph>
        )}
        {isLoadingPackages && (
          <Paragraph>
            We are getting your packages <InlineLoader />
          </Paragraph>
        )}
        {!isLoadingPackages &&
          !errorFetchingPackages &&
          !!packages &&
          Object.keys(packages)
            .sort()
            .map((key) => {
              const packageKey = checkIsKeyInEntityPackageKeys(key);

              if (packageKey) {
                return (
                  <Row mb>
                    {packageKeysToNames[packageKey]}
                    <Controller
                      name={packageKey}
                      control={control}
                      defaultValue={packages[packageKey]}
                      render={({ onChange, value }) => {
                        const isActive = isPackageEnabled(value?.enabledUntil);

                        return (
                          <StaleSwitch
                            id={packageKey}
                            isOn={isActive}
                            handleToggle={() =>
                              onChange(
                                isActive
                                  ? {
                                      enabledUntil: null,
                                      freeTrialUsed: !!packages[packageKey],
                                    }
                                  : {
                                      enabledUntil: dayjs().add(1, 'year'),
                                      freeTrialUsed: !!packages[packageKey],
                                    }
                              )
                            }
                          />
                        );
                      }}
                    />
                  </Row>
                );
              }

              return null;
            })}
      </WhiteContentContainer>
      <WhiteFooterContainer>
        <Row>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingPackages || errorFetchingPackages}
          >
            Save packages
          </Button>
        </Row>
      </WhiteFooterContainer>
    </form>
  );
};

export default Packages;
