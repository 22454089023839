import useUrlValues from 'hooks/useUrlValues';
import { FC } from 'react';
import DateRangeInfo from './components/DateRangeInfo/DateRangeInfo';
import { TCashflowAtRiskNew } from 'types';
import { Loader } from 'components';
import CashflowsTable from './components/CashflowsTable/CashflowsTable';
import { TRisksDataPeriod } from 'pages/Risks/types';

interface IOwnProps {
  cashflowsRisksData: TCashflowAtRiskNew[];
  isLoadingCashflowsRisksData: boolean;
  isLoadingAndHaveData: boolean;
}

const DataTable: FC<IOwnProps> = ({
  cashflowsRisksData,
  isLoadingCashflowsRisksData,
  isLoadingAndHaveData,
}) => {
  const { dateRange, period } = useUrlValues('dateRange', 'period');

  if (isLoadingCashflowsRisksData && !isLoadingAndHaveData) {
    return <Loader size="large" style={{ alignSelf: 'center' }} />;
  }

  if (dateRange) {
    return <DateRangeInfo dateRange={dateRange} />;
  }

  if (!!period) {
    return (
      <CashflowsTable
        period={period as TRisksDataPeriod}
        cashflowsRisksData={cashflowsRisksData}
      />
    );
  }

  return null;
};

export default DataTable;
