import {
  FC,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  useImperativeHandle,
} from 'react';
import { SortingRule } from 'react-table';
import { isEqual } from 'lodash';
import { useTheme } from 'styled-components';
import useUrlValues from 'hooks/useUrlValues';
import { IGroupedInvoicesFromSearch } from 'types';
import { Col, Loader, Table } from 'components';
import { generateTableColumns } from './tableColumnsGenerator';
import useInvoices from '../InvoicesTable/hooks/useInvoices';
import { getInvoicesFilters } from 'pages/Invoices/utils';
import { useStoreState } from 'state';
import ViewDetailsPopup from './components/ViewDetailsPopup/ViewDetailsPopup';
import useDeviceWidth from 'hooks/useDeviceWidth';
import GroupedInvoiceTile from './components/GroupedInvoiceTile/GroupedInvoiceTile';
import { ExposedUseTableProps } from 'components/shared/Table/types';
import { getSortQueryStringFromTableSort } from 'utils/search';
import { initialGroupedInvoicesSortBy } from './constants';
import { IGroupedInvoicesTableActions } from './types';

interface IOwnProps {
  invoicesTableActionsRef?: React.MutableRefObject<
    IGroupedInvoicesTableActions | undefined
  >;
}

const GroupedInvoicesTable: FC<IOwnProps> = ({ invoicesTableActionsRef }) => {
  const theme = useTheme();
  const {
    currency,
    tab,
    search,
    filter,
    dueDateFrom,
    dueDateTo,
    fullyPaidOnDateFrom,
    fullyPaidOnDateTo,
  } = useUrlValues(
    'currency',
    'tab',
    'search',
    'filter',
    'dueDateFrom',
    'dueDateTo',
    'fullyPaidOnDateFrom',
    'fullyPaidOnDateTo'
  );
  const entityCurrencyCode = useStoreState(
    (state) => state.UserState.entityCurrencyCode
  );
  const [isLoadingFirstInvoicesPage, setIsLoadingFirstInvoicesPage] = useState(
    true
  );
  const [
    groupedInvoicesForViewDetails,
    setGroupedInvoicesForViewDetails,
  ] = useState<IGroupedInvoicesFromSearch>();
  const { isMobile } = useDeviceWidth();
  const [sortState, setSortState] = useState<
    SortingRule<IGroupedInvoicesFromSearch>[]
  >(initialGroupedInvoicesSortBy);
  const tableRef = useRef<ExposedUseTableProps<IGroupedInvoicesFromSearch>>(
    null
  );
  const handleSortChange = useCallback(
    (tableSortState: SortingRule<IGroupedInvoicesFromSearch>[]) => {
      if (isEqual(tableSortState, sortState)) {
        return;
      }

      setSortState(tableSortState);
    },
    [sortState]
  );

  const {
    afterKey,
    currentPage,
    hasMoreToLoad,
    isLoadingMoreInvoices,
    fetchInvoicesWithSideEffects,
    invoices,
  } = useInvoices();

  const fetchFirstInvoicesPage = useCallback(async () => {
    if (!tab) {
      return;
    }

    const filters = getInvoicesFilters({
      tab,
      filterName: filter,
      currency,
      dueDateFrom,
      dueDateTo,
      fullyPaidOnDateFrom,
      fullyPaidOnDateTo,
    });

    const sortFields = getSortQueryStringFromTableSort(sortState);

    tableRef.current?.toggleAllRowsSelected(false);

    fetchInvoicesWithSideEffects({
      page: 1,
      searchQuery: search ?? '',
      filters,
      grouped: true,
      reset: true,
      sortFields,
    }).finally(() => {
      setIsLoadingFirstInvoicesPage(false);
    });
  }, [
    currency,
    dueDateFrom,
    dueDateTo,
    fetchInvoicesWithSideEffects,
    filter,
    fullyPaidOnDateFrom,
    fullyPaidOnDateTo,
    search,
    sortState,
    tab,
  ]);

  useEffect(() => {
    fetchFirstInvoicesPage();
  }, [fetchFirstInvoicesPage]);

  const onLoadMoreItems = useCallback(async () => {
    if (isLoadingMoreInvoices) {
      return;
    }

    return fetchInvoicesWithSideEffects({
      page: currentPage + 1,
      searchQuery: search ?? '',
      filters: getInvoicesFilters({
        tab,
        filterName: filter,
        currency,
        dueDateFrom,
        dueDateTo,
        fullyPaidOnDateFrom,
        fullyPaidOnDateTo,
      }),
      grouped: true,
      sortFields: getSortQueryStringFromTableSort(sortState),
      afterKey,
    });
  }, [
    currency,
    currentPage,
    dueDateFrom,
    dueDateTo,
    fetchInvoicesWithSideEffects,
    filter,
    fullyPaidOnDateFrom,
    fullyPaidOnDateTo,
    isLoadingMoreInvoices,
    search,
    tab,
    sortState,
    afterKey,
  ]);

  const tableColumns = useMemo(
    () =>
      generateTableColumns({
        tab,
        entityCurrencyCode,
        onViewDetails: setGroupedInvoicesForViewDetails,
      }),
    [entityCurrencyCode, tab]
  );

  useImperativeHandle(
    invoicesTableActionsRef,
    () => ({
      fetchFirstInvoicesPage,
    }),
    [fetchFirstInvoicesPage]
  );

  return (
    <>
      {isLoadingFirstInvoicesPage && <Loader size="large" />}
      {!isLoadingFirstInvoicesPage && !isMobile && (
        <Table<IGroupedInvoicesFromSearch>
          ref={tableRef}
          autoResetGlobalFilter={false}
          autoResetSortBy={false}
          autoResetFilters={false}
          autoResetExpanded={false}
          data={invoices as IGroupedInvoicesFromSearch[]}
          columns={tableColumns}
          isVirtualized
          withInfiniteLoading
          onLoadMoreItems={onLoadMoreItems}
          loadingThreshold={10}
          isLoadingMoreItems={isLoadingMoreInvoices}
          itemsCount={invoices.length}
          hasMoreToLoad={hasMoreToLoad}
          expandOneRowAtATime
          sortable
          manualSortBy
          onSort={handleSortChange}
          initialState={{
            sortBy: initialGroupedInvoicesSortBy,
          }}
        />
      )}

      {!isLoadingFirstInvoicesPage && isMobile && (
        <Col mt gap={theme.spacing.xxs}>
          {(invoices as IGroupedInvoicesFromSearch[]).map((groupedInvoice) => (
            <GroupedInvoiceTile
              key={groupedInvoice.key}
              tab={tab}
              onLoadMoreItems={onLoadMoreItems}
              loadingThreshold={10}
              isLoadingMoreItems={isLoadingMoreInvoices}
              data={groupedInvoice}
              hasMoreToLoad={hasMoreToLoad}
              onViewDetails={setGroupedInvoicesForViewDetails}
            />
          ))}
        </Col>
      )}
      {useMemo(() => {
        if (!groupedInvoicesForViewDetails || isLoadingFirstInvoicesPage) {
          return null;
        }

        return (
          <ViewDetailsPopup
            tab={tab}
            groupedInvoices={groupedInvoicesForViewDetails}
            onClose={() => setGroupedInvoicesForViewDetails(undefined)}
          />
        );
      }, [groupedInvoicesForViewDetails, isLoadingFirstInvoicesPage, tab])}
    </>
  );
};

export default GroupedInvoicesTable;
