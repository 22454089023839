import { ChartData } from 'chart.js';
import {
  parseIntoCurrencyStringWithSymbol,
  parseIntoShortNumberWithSymbol,
} from 'utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DB_DATE_FORMAT } from 'variables';
import { TCashflowAtRiskNew } from 'types';
import Chart from 'components/shared/Chart/Chart';
import { useStoreState } from 'state';
import { TRisksDataPeriod } from 'pages/Risks/types';
import { getDateFormatFromPeriodType } from 'pages/Risks/components/DataTable/components/CashflowsTable/utils';
import { useRef } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { getElementFromEvent } from 'components/shared/Chart/utils';

dayjs.extend(utc);

const ChartCashflows = ({
  data,
  currencyCode,
  period,
  onChartClick,
}: {
  data: TCashflowAtRiskNew[];
  currencyCode: string | null;
  period: TRisksDataPeriod | null;
  /** @param period date string */
  onChartClick: (period: string, periodIndex: number) => void;
}) => {
  const labels = data.map((item) =>
    dayjs.utc(item.date, DB_DATE_FORMAT).toDate()
  );
  const currencyByCode = useStoreState(
    (state) => state.CurrenciesState.currencyByCode
  );
  const dateDisplayFormat = getDateFormatFromPeriodType(period ?? 'month');
  const chartRef = useRef<ChartJSOrUndefined<'line' | 'bar'>>();
  const chartData: ChartData<'line' | 'bar', number[], Date> = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Closing balance',
        data: data.map((item) => item.closingBalance),
        borderColor: 'rgba(218, 192, 146, 0.6)',
        backgroundColor: 'rgba(218, 192, 146, 0.9)',
      },
      {
        type: 'bar',
        label: 'Inflows',
        data: data.map((item) => item.receivables),
        borderColor: 'rgba(146, 218, 165, 0.6)',
        backgroundColor: 'rgba(146, 218, 165, 0.9)',
      },
      {
        type: 'bar',
        label: 'Outflows',
        data: data.map((item) => item.payables),
        borderColor: 'rgba(233, 50, 50, 0.6)',
        backgroundColor: 'rgba(233, 50, 50, 0.9)',
      },
      {
        type: 'bar',
        label: 'Hedges',
        data: data.map((item) => item.hedges),
        borderColor: 'rgba(52, 55, 233, 0.6)',
        backgroundColor: 'rgba(52, 55, 233, 0.9)',
      },
    ],
  };

  return (
    <Chart
      ref={chartRef}
      type="line"
      data={chartData}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              label: (context) =>
                `${context.dataset.label}: ${parseIntoCurrencyStringWithSymbol(
                  context.raw as number,
                  currencyByCode(currencyCode)?.symbol,
                  0
                )}`,
            },
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: period ?? 'month',
              tooltipFormat: dateDisplayFormat,
              displayFormats: {
                week: dateDisplayFormat,
                month: dateDisplayFormat,
                quarter: dateDisplayFormat,
              },
            },
            grid: {
              display: false,
            },
            ticks: {
              source: 'data',
            },
          },
          y: {
            ticks: {
              callback: (value) =>
                parseIntoShortNumberWithSymbol(value as number),
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        elements: {
          point: {
            radius: 5,
          },
          line: {
            tension: 0.4,
          },
        },
      }}
      onClick={(event) => {
        if (!chartRef.current) {
          return;
        }

        const element = getElementFromEvent(chartRef.current, event);

        if (!element) {
          return;
        }

        onChartClick(data[element.index].date, element.index);
      }}
    />
  );
};

export default ChartCashflows;
