import { AxiosPrivateFirebaseInstance } from 'settings';
import { IBalance } from 'types/balances';
import { openQuery } from 'utils';
import firestore from './firestore';
import { IResponse } from 'types';

export interface SubscribeToBalancesParams {
  entityId: string;
  callback: (balances: IBalance[]) => void;
}

export const subscribeToBalances = ({
  entityId,
  callback,
}: SubscribeToBalancesParams) => {
  return firestore
    .collection('entities')
    .doc(entityId)
    .collection('balances')
    .onSnapshot(
      (query) => callback(openQuery(query)),
      (error) =>
        console.warn('Failed to subscribe to entity balances. Error: ', error)
    );
};

export const refreshBalances = async () =>
  AxiosPrivateFirebaseInstance.post('/balances/refresh');

export interface PayWithBalanceParams {
  entityId: string;
  currency: string;
  amount: number;
}

export const payWithBalance = async (payload: PayWithBalanceParams) =>
  AxiosPrivateFirebaseInstance.post(`/balances/pay`, payload);

export interface IBalanceAndSource extends IBalanceAmount {
  sourceSystemId: string;
  source: 'currencyCloud' | 'openBanking' | 'accountingSystem';
  name: string;
}

interface IBalanceAmount {
  currency: string;
  amount: number;
}

interface IBalancesAmountAndRate extends IBalanceAmount {
  rate: number;
}

export interface IDailyAccountBalance extends IBalanceAndSource {
  /** YYYY-MM-DD */
  date: string;
  translatedToEntityCurrency?: IBalancesAmountAndRate;
  /**
   * Users/Accountants often want to see all their balances in a single currency,
   * which can be different than the entity currency. When a consolidated currency is
   * available in the relevant settings then this section will be populated accordingly.
   */
  translatedToConsolidatedCurrency?: IBalancesAmountAndRate;
}

export interface IDailyAccountBalances {
  date: string;
  endOfDayBalances: IDailyAccountBalance[];
}

export const getDailyBalanceBetweenDates = ({
  dateFrom,
  dateTo,
}: {
  dateFrom: string;
  dateTo: string;
}) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IDailyAccountBalances[]>>(
    `/balances/daily?fromDate=${dateFrom}&toDate=${dateTo}`
  );
