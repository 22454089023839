import { ISystemFields } from './systemFields';

interface IInvoiceLineItem {
  itemCode: string;
  name: string;
  description: string;
  unitAmount: number;
  lineAmount: number;
  taxAmount?: number;
  quantity: number;
  discountRate?: number;
  discountAmount?: number;
  externalRefs?: {
    sourceSystem?: TExternalSystemType;
    sourceSystemId?: string;
  };
}

export interface IInvoice extends ISystemFields {
  id: string;
  contactId?: string | null;
  invoiceNumber: string;
  type: 'Receivable' | 'Payable';
  status: INVOICE_STATUSES;

  reference?: string;
  currency: string;
  externalCurrencyRate?: number;
  date: string;
  dueDate: string;
  /** ISO date string */
  plannedPaymentDate?: string;

  amountDue: number;
  amountPaid?: number;
  isDiscounted?: boolean;
  totalDiscountAmount?: number;
  subTotalAmount?: number;
  totalTaxAmount?: number;
  totalAmount: number;
  lineAmountTypes?: string;
  lineItems?: IInvoiceLineItem[];
  fullyPaidOnDate?: string;
  payments?: IInvoicePayment[];

  externalRefs?: {
    sourceSystem?: TExternalSystemType;
    sourceSystemId?: string;
    sourceSystemContactId?: string;
    sourceSystemContactName?: string;
    sourceSystemRepeatingInvoiceId?: string;
    deepLinkUrl?: string;
  };

  trackingId?: string;
  contractId?: string;
  contractAssignment?: boolean;
  transferIds?: string[];
  fundingIds?: string[];
  excludeFromRisk?: boolean;
  approval?: IInvoiceApproval;

  permissions?: {
    xero?: boolean;
  };

  hasAttachments?: boolean;
  attachments?: IAttachment[];

  lastSyncFromExternalSource?: ISourceSyncData;
  lastSyncToExternalSource?: ISourceSyncData;
}

type TSourceSyncDataStatus = 'queued' | 'success' | 'error';

export interface ISourceSyncData {
  timestamp: {
    seconds: number;
    nanoseconds: number;
  };
  /** @deprecated Use status instead */
  success?: boolean;
  status?: TSourceSyncDataStatus;
}

interface IAttachment {
  id: string;
  fileName: string; // "sample.pdf"
  mimeType?: string; // "application/pdf"
  url: string; // "https://api.xero.com/api.xro/2.0/Invoices/f853f034-ef27-4e0b-a5d0-12b2b156babb/Attachments/sample.pdf"
}

interface IInvoicePayment {
  id: string;
  recipientName: string;
  amount: number;
  date: string;
  currency: string;
}

export enum INVOICE_STATUSES {
  draft = 'DRAFT',
  submitted = 'SUBMITTED',
  deleted = 'DELETED',
  authorised = 'AUTHORISED',
  paymentScheduled = 'PAYMENT SCHEDULED',
  partiallyPaid = 'PARTIALLY PAID',
  processingPayment = 'PROCESSING PAYMENT',
  paid = 'PAID',
  voided = 'VOIDED',
}

export interface IInvoiceFromSearch
  extends Pick<
    IInvoice,
    | 'id'
    | 'amountDue'
    | 'amountPaid'
    | 'contactId'
    | 'contractId'
    | 'type'
    | 'reference'
    | 'currency'
    | 'invoiceNumber'
    | 'trackingId'
    | 'totalAmount'
    | 'excludeFromRisk'
    | 'status'
    | 'contractAssignment'
    | 'fundingIds'
  > {
  transferId?: string;
  owner: string;
  contactPhone?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactRecipientName?: string;
  contactEmail?: string;
  contactEnabled?: boolean;
  contactShouldPausePayments?: boolean;
  externalRefsSourceSystem?: TExternalSystemType;

  /** ISO date string */
  created: string;
  /** ISO date string */
  updated: string;
  /** ISO date string */
  dueDate: string;
  /** ISO date string */
  date: string;
  /** ISO date string */
  fullyPaidOnDate?: string;
  /** ISO date string */
  plannedPaymentDate?: string;
  profitAndLoss: number | null;
  invoiceRate: number | null;
  prebookRate: number | null;
  /** Available only for Paid invoices */
  transferRate: number | null;
  hasPayments?: boolean;
  externalCurrencyRate?: number;
  externalRefsSourceSystemContactId?: string;
  externalRefsSourceSystemContactName?: string;
  externalRefsDeepLinkUrl?: string;
  approvalStatus?: IInvoiceApproval['status'];
  approvalType?: IInvoiceApproval['type'];
  approvedBy?: string;
  approvedBy2?: string;
  approvedAt?: string;
  approvedAt2?: string;
  approvalMessageForUi?: string;
  approvalSubmittedBy?: string;
  approvalApprovers?: string[];
  lastSyncFromExternalSourceStatus?: ISourceSyncData['status'];
  /** ISO date string */
  lastSyncFromExternalSourceDate?: string;
  lastSyncToExternalSourceStatus?: ISourceSyncData['status'];
  /** ISO date string */
  lastSyncToExternalSourceDate?: string;
}

export interface IInvoiceApproval {
  status: 'submitted' | 'approved' | null;
  type?: 'exceptional' | 'auto' | 'default';
  requiredNumberOfApprovers?: number;
  submittedBy: string;
  submittedAt: string;
  approvedBy?: string;
  approvedAt?: string;
  approvedBy2?: string;
  approvedAt2?: string;
  messageForUi?: string;
  approvers?: string[];
}

export interface IGroupedInvoicesFromSearch {
  key: string;
  docsCount: number;
  results: IInvoiceFromSearch[];
  totalAmountDueOrTotal: number;
}

type TInvoicesAggregationValuePerCurrency = {
  value: number;
  count: number;
};

export type TInvoicesAggregationPerFilter = {
  value: number;
  count: number;
  currencies: string[];
};

export type TInvoicesAggregationPerCurrency = {
  paid?: TInvoicesAggregationValuePerCurrency;
  outstanding?: TInvoicesAggregationValuePerCurrency;
  overdue?: TInvoicesAggregationValuePerCurrency;
  dueWithin14Days?: TInvoicesAggregationValuePerCurrency;
  payable?: TInvoicesAggregationValuePerCurrency;
  approved?: TInvoicesAggregationValuePerCurrency;
  submitted?: TInvoicesAggregationValuePerCurrency;
  receivables?: TInvoicesAggregationValuePerCurrency;
  receivablesOverdue?: TInvoicesAggregationValuePerCurrency;
  receivablesDueIn14Days?: TInvoicesAggregationValuePerCurrency;
};

export type TInvoicesAggregations = {
  /** key is a currency, sub key is a filter name like outstanding, paid, etc. */
  perCurrency: {
    [key: string]: TInvoicesAggregationPerCurrency;
  };
  /** key is a filter name like outstanding, paid, etc., value is a sum between all available currencies */
  perFilter: {
    paid?: TInvoicesAggregationPerFilter;
    outstanding?: TInvoicesAggregationPerFilter;
    overdue?: TInvoicesAggregationPerFilter;
    dueWithin14Days?: TInvoicesAggregationPerFilter;
    payable?: TInvoicesAggregationPerFilter;
    approved?: TInvoicesAggregationPerFilter;
    submitted?: TInvoicesAggregationPerFilter;
    receivables?: TInvoicesAggregationPerFilter;
    receivablesOverdue?: TInvoicesAggregationPerFilter;
    receivablesDueIn14Days?: TInvoicesAggregationPerFilter;
  };
};

export type TExternalSystemType =
  | 'xero'
  | 'msDynamics'
  | 'netSuite'
  | 'externalApi'
  | 'csvUpload';

export interface IInvoiceDetailsFromAttachment {
  accountNumber?: string;
}

export type TInvoicePageTabs =
  | 'outstanding'
  | 'paid'
  | 'submitted'
  | 'approved'
  | 'paymentRun'
  | 'receivables'
  | 'received';
