import { useMemo, useState } from 'react';
import {
  IntegrationTileWrapper,
  IntegrationSvg,
  IntegrationTextHint,
  IntegrationParagraph,
  IntegrationRow,
  ComingSoon,
  CSVRow,
} from './IntegrationTile.styles';
import { Col, PermissionsChecker, BetaTag, TextHint, Row } from 'components';
import { useHistory } from 'react-router-dom';
import { INTEGRATION_TYPE } from 'types';
import { IAvailableIntegration } from 'types/integrations';
import { getInvoiceManualUploadLink } from 'utils/links';
import Button from 'components/shared/Button/Button';
import { useTheme } from 'styled-components';
import { errorHandler } from 'utils/errors';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Link from 'components/shared/Link/Link';
import { useStoreState } from 'state';
import useIntegrationEngine from 'hooks/useIntegrationEngine';

interface Props {
  integration: IAvailableIntegration;
}
const IntegrationTile: React.FC<Props> = ({ integration }) => {
  const theme = useTheme();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const { id, name, system, img, type, available, isBeta } = integration;
  const { integrationsSummary } = useStoreState((state) => state.UserState);
  const sourceCompanyName = integrationsSummary?.sourceCompanyName;

  const {
    onLogin,
    onImportIntegrationEngineData,
    isImporting,
    isDisconnecting,
    onDisconnect,
    isLoading,
    isIntegrated,
    onManageSettings,
    onManageLogs,
    reauthenticateIntegration,
    accountingIntegrationSystem,
  } = useIntegrationEngine();

  const connect = async () => {
    try {
      await onLogin?.(system);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const disconnect = async () => {
    try {
      await onDisconnect?.();
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const isTileIntegrated =
    isIntegrated && accountingIntegrationSystem === system;

  const additionalText = useMemo(() => {
    if (isTileIntegrated && !!sourceCompanyName) {
      return sourceCompanyName;
    } else {
      return type === INTEGRATION_TYPE.business
        ? 'Business system'
        : 'Accounting system';
    }
  }, [sourceCompanyName, isTileIntegrated, type]);

  return (
    <IntegrationTileWrapper flex={1}>
      {id !== 'csv' && (
        <Row>
          <IntegrationSvg>
            <use xlinkHref={img} />
          </IntegrationSvg>
          <Col>
            <IntegrationParagraph variant="bold">
              {name}
              {isBeta && <BetaTag />}
            </IntegrationParagraph>
            <IntegrationTextHint color="grey">
              {additionalText}
            </IntegrationTextHint>

            {!isTileIntegrated && system === 'dynamics' && (
              <Row mb alignItems="flex-start">
                <Col mr>
                  <TextHint>
                    <strong>
                      HedgeFlows Payments & Currency Management App{' '}
                    </strong>
                    from{' '}
                    <Link
                      href="https://appsource.microsoft.com/en-us/product/DynamicsBC/PUBID.hedgeflows%7CAID.hedgeflows%7CPAPPID.fbfa19e5-690d-4a90-b995-07ebbb756aef "
                      target="_blank"
                      rel="noreferrer"
                      display="inline"
                    >
                      Microsoft AppSource
                    </Link>{' '}
                    is required.
                  </TextHint>
                  <TextHint>
                    Please confirm you’ve installed it before connecting.
                  </TextHint>
                </Col>

                <Checkbox
                  onChange={() => setIsChecked((prev) => !prev)}
                  checked={isChecked}
                />
              </Row>
            )}
          </Col>
        </Row>
      )}

      <IntegrationRow flex={1}>
        {id === 'csv' && (
          <PermissionsChecker action="create" resource="cashflows_manual">
            <CSVRow>
              Or manually
              <Button
                ml
                mlValue={theme.spacing.xs}
                variant="link"
                onClick={() => history.push(getInvoiceManualUploadLink())}
              >
                upload CSV invoices
              </Button>
            </CSVRow>
          </PermissionsChecker>
        )}

        {isTileIntegrated ? (
          <Row>
            {!!onImportIntegrationEngineData && (
              <Button
                variant="link"
                onClick={() => onImportIntegrationEngineData()}
                disabled={isImporting || reauthenticateIntegration}
                isLoading={isImporting}
              >
                Refresh data
              </Button>
            )}
            {!!onManageLogs && (
              <Button
                ml
                variant="link"
                disabled={reauthenticateIntegration}
                onClick={onManageLogs}
              >
                View logs
              </Button>
            )}
            {!!onManageSettings && (
              <PermissionsChecker action="update" resource="integrations">
                <Button
                  ml
                  variant="link"
                  disabled={reauthenticateIntegration}
                  onClick={onManageSettings}
                >
                  Settings
                </Button>
              </PermissionsChecker>
            )}

            {!!onLogin && reauthenticateIntegration && (
              <PermissionsChecker action="update" resource="integrations">
                <Button
                  ml
                  variant="link"
                  onClick={connect}
                  disabled={isLoading}
                >
                  Reauthenticate
                </Button>
              </PermissionsChecker>
            )}
            {id !== 'csv' && (
              <PermissionsChecker action="update" resource="integrations">
                <Button
                  ml
                  variant="link"
                  onClick={!!onDisconnect ? disconnect : undefined}
                  disabled={!!onDisconnect ? isDisconnecting : true}
                >
                  Disconnect {!onDisconnect && '(coming soon)'}
                </Button>
              </PermissionsChecker>
            )}
          </Row>
        ) : (
          id !== 'csv' &&
          !!onLogin && (
            <Row>
              <PermissionsChecker action="update" resource="integrations">
                <Button
                  ml
                  variant="link"
                  onClick={connect}
                  disabled={isLoading || (system === 'dynamics' && !isChecked)}
                >
                  Connect
                </Button>
              </PermissionsChecker>
            </Row>
          )
        )}

        {!available && <ComingSoon>Coming soon</ComingSoon>}
      </IntegrationRow>
    </IntegrationTileWrapper>
  );
};

export default IntegrationTile;
