import { FC, ReactNode, useMemo, useEffect } from 'react';
import { ICurrency, IRateContract } from 'types';
import Table from '../Table/Table';
import { useHistory } from 'react-router-dom';
import { IExternalHedge } from 'types/externalHedges';
import { useStoreState } from 'state';
import CounterTitle from '../CounterTitle/CounterTitle';
import { useTheme } from 'styled-components';
import _orderBy from 'lodash.orderby';
import {
  generatePrebookTableColumns,
  TPrebookTableFilterValue,
  filterTypes,
} from './tableColumnsGenerator';
import useUrlValues from 'hooks/useUrlValues';
import useTableFiltering from 'hooks/useTableFiltering';
import { TableProps } from '../Table/types';

interface OwnProps
  extends Pick<TableProps<IRateContract>, 'isVirtualized' | 'onRowClick'> {
  currencyCode?: ICurrency['code'];
  withTitle?: boolean;
  Controls?: ReactNode;
}

const PrebookTable: FC<OwnProps> = ({
  isVirtualized,
  currencyCode,
  withTitle,
  onRowClick,
  Controls,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { filter: filterFromUrl } = useUrlValues('filter');

  const { setFilter, tableRef } = useTableFiltering<
    TPrebookTableFilterValue,
    IRateContract | IExternalHedge
  >({
    filterTypeName: 'filtering',
    initialFilterValue:
      (filterFromUrl as TPrebookTableFilterValue) ?? 'default',
  });

  useEffect(() => {
    setFilter(filterFromUrl as TPrebookTableFilterValue);
  }, [filterFromUrl, setFilter]);

  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { rateContracts, rateContractsByBuyCurrency } = useStoreState(
    (state) => state.RateContractsState
  );
  const { externalHedges, externalHedgesByBuyCurrency } = useStoreState(
    (state) => state.ExternalHedgesState
  );

  const tableColumns = useMemo(
    () => generatePrebookTableColumns(currencyByCode, history),
    [currencyByCode, history]
  );

  const tableData = useMemo(() => {
    if (currencyCode && currencyCode !== 'all') {
      return [
        ...rateContractsByBuyCurrency(currencyCode),
        ...externalHedgesByBuyCurrency(currencyCode),
      ];
    }

    return [...rateContracts, ...externalHedges];
  }, [
    currencyCode,
    rateContracts,
    rateContractsByBuyCurrency,
    externalHedges,
    externalHedgesByBuyCurrency,
  ]);

  return (
    <>
      {withTitle && (
        <CounterTitle
          mb
          mbValue={theme.spacing.l}
          title="Prebooked FX"
          count={tableData.length}
        />
      )}

      {Controls}

      <Table<IRateContract | IExternalHedge>
        ref={tableRef}
        // @ts-expect-error TS(2322) FIXME: Type '((record: IRateContract) => unknown) | undef... Remove this comment to see the full error message
        onRowClick={onRowClick}
        data={_orderBy(tableData, '_created', 'desc')}
        columns={tableColumns}
        defaultRowHeight={70}
        isVirtualized={isVirtualized}
        autoResetGlobalFilter={false}
        autoResetSortBy={false}
        autoResetFilters={false}
        autoResetSelectedRows={false}
        filterTypes={filterTypes}
        initialState={{
          filters: [{ id: 'direction', value: filterFromUrl ?? 'default' }],
        }}
      />
    </>
  );
};

export default PrebookTable;
