import { FC, useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Title, StaleSelectMenu, Paragraph, Popup, Row } from 'components';
import { useStoreState } from 'state';
import { useQuery } from 'hooks';
import _orderBy from 'lodash.orderby';
import { ICurrencyInput } from 'types';
import { transformCurrencyToSelectOption } from 'utils';
import { useTheme } from 'styled-components';
import { TPlatformNames } from 'types/integrations';
import Button from '../Button/Button';

interface Inputs {
  excludedCurrencies: ICurrencyInput[];
}

interface OwnProps {
  onSaveValues: (values: string[]) => void;
  platformName: TPlatformNames;
}

const StepCurrencies: FC<OwnProps> = ({ onSaveValues, platformName }) => {
  const theme = useTheme();
  const URLQuery = useQuery();
  const firstIntegration = URLQuery.get('firstIntegration') === 'true';

  const [currenciesSearchValue, setCurrenciesSearchValue] = useState('');
  const { currencies } = useStoreState((state) => state.CurrenciesState);
  const { integrationEngineSettings } = useStoreState(
    ({ UserState }) => UserState
  );
  const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState(false);

  const savedExcludedCurrencies =
    integrationEngineSettings.settings?.excludedCurrencies;

  const defaultCurrencies = useMemo(() => {
    if (firstIntegration) {
      return [];
    }

    return currencies
      .filter(
        (currency) =>
          savedExcludedCurrencies?.find(
            (excluded) => excluded === currency.code
          ) && currency
      )
      .map(transformCurrencyToSelectOption);
  }, [firstIntegration, currencies, savedExcludedCurrencies]);

  const currenciesOptions = useMemo(
    () => _orderBy(currencies.map(transformCurrencyToSelectOption), 'label'),
    [currencies]
  );

  const { watch, control, handleSubmit } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      excludedCurrencies: defaultCurrencies,
    },
  });

  const excludedCurrenciesWatch = watch('excludedCurrencies');

  const onSubmit = async ({ excludedCurrencies }: any) => {
    const excludedCurrenciesToUse = excludedCurrencies?.map(
      (currency: any) => currency.value.code
    );
    onSaveValues(excludedCurrenciesToUse);
  };

  const onCloseConfirmationPopup = () => setIsOpenConfirmationPopup(false);

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        What currencies should we ignore?
      </Title>

      <Paragraph mb mbValue={theme.spacing.xl}>
        Please select currencies that you don’t want to manage via HedgeFlows
        from the list:
      </Paragraph>

      <form id="excluded-currencies-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="excludedCurrencies"
          defaultValue={null}
          render={({ onChange, value, name }) => {
            return (
              <StaleSelectMenu
                id={name}
                name={name}
                label="Currencies to exclude:"
                isMulti
                data={currenciesOptions}
                value={value}
                onChange={(item) => {
                  if (!Array.isArray(item)) {
                    onChange(null);
                  } else {
                    onChange(item);
                  }
                }}
                inputValue={currenciesSearchValue}
                onInputChange={setCurrenciesSearchValue}
                menuPlacement="top"
                menuPosition="absolute"
                withBackdrop={false}
              />
            );
          }}
        />
      </form>

      <Button
        mt
        mtValue={theme.spacing.xl}
        onClick={
          firstIntegration && excludedCurrenciesWatch.length > 0
            ? () => setIsOpenConfirmationPopup(true)
            : handleSubmit(onSubmit)
        }
      >
        Continue
      </Button>

      {isOpenConfirmationPopup && (
        <Popup
          HeaderContent={<Title variant="h4">Confirm currency exclude</Title>}
          FooterContent={
            <Row flex={1}>
              <Button variant="secondary" onClick={onCloseConfirmationPopup}>
                Cancel
              </Button>

              <Button form="excluded-currencies-form">Confirm</Button>
            </Row>
          }
          width="439px"
          onClose={onCloseConfirmationPopup}
        >
          <Paragraph>
            Do you want to exclude{' '}
            {excludedCurrenciesWatch
              .map((currencyValue) => currencyValue.label)
              .join(', ')}{' '}
            ?
          </Paragraph>
        </Popup>
      )}
    </>
  );
};

export default StepCurrencies;
