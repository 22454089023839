import { FC, useCallback, useState, useRef, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { VariableSizeList } from 'react-window';
import { IInvoiceFromSearch } from 'types';
import { Col } from 'components/shared/Col/Col';
import InfiniteLoader from 'components/shared/Table/components/InfiniteLoader';
import MobileInvoicesTile from './components/MobileInvoicesTile/MobileInvoicesTile';
import InlineLoader from 'components/shared/InlineLoader/InlineLoader';
import { TableProps } from 'components/shared/Table/types';
import { InfiniteLoadingIndicatorWrapper } from 'components/shared/Table/Table.styles';

type TTablePropsToPick =
  | 'data'
  | 'withInfiniteLoading'
  | 'onLoadMoreItems'
  | 'itemsCount'
  | 'loadingThreshold'
  | 'isLoadingMoreItems'
  | 'hasMoreToLoad'
  | 'selectable';

interface OwnProps
  extends Pick<TableProps<IInvoiceFromSearch>, TTablePropsToPick> {
  isPaid: boolean;
  isSubmitted: boolean;
  onSelectInvoices?: (invoices: IInvoiceFromSearch[]) => void;
}

const MobileInvoicesList: FC<OwnProps> = ({
  data,
  withInfiniteLoading,
  onLoadMoreItems,
  loadingThreshold,
  isLoadingMoreItems,
  itemsCount = 0,
  hasMoreToLoad,
  onSelectInvoices: onSelectInvoicesCallback,
  isPaid,
  isSubmitted,
  selectable,
}) => {
  const theme = useTheme();
  const isItemLoaded = useCallback(
    (index) => !hasMoreToLoad || index < data.length,
    [hasMoreToLoad, data.length]
  );
  const [
    mobileInvoicesWrapperHeight,
    setMobileInvoicesWrapperHeight,
  ] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState<
    IInvoiceFromSearch[]
  >([]);
  const mobileInvoicesWrapperRef = useRef<HTMLTableSectionElement>(null);

  const onSelectInvoices = useCallback(
    (invoices: IInvoiceFromSearch[]) => {
      setSelectedInvoices(invoices);

      onSelectInvoicesCallback?.(invoices);
    },
    [onSelectInvoicesCallback]
  );

  useEffect(() => {
    if (mobileInvoicesWrapperRef.current) {
      setMobileInvoicesWrapperHeight(
        mobileInvoicesWrapperRef.current.getBoundingClientRect().height
      );
    }
  }, [mobileInvoicesWrapperRef]);

  return (
    <>
      <Col ref={mobileInvoicesWrapperRef} gap={theme.spacing.xxs} flex={1}>
        <InfiniteLoader
          enabled={!!withInfiniteLoading}
          isItemLoaded={isItemLoaded}
          itemCount={hasMoreToLoad ? itemsCount + 1 : itemsCount}
          loadMoreItems={onLoadMoreItems}
          threshold={loadingThreshold}
        >
          {(infiniteLoaderProps) => {
            const { onItemsRendered } = infiniteLoaderProps || {};

            return (
              <VariableSizeList
                height={mobileInvoicesWrapperHeight}
                itemCount={itemsCount}
                itemSize={() => 93}
                width="100%"
                style={{
                  maxHeight: mobileInvoicesWrapperHeight,
                  minWidth: '100%',
                  overflow: 'overlay',
                }}
                onItemsRendered={onItemsRendered}
              >
                {(variableSizeListData) => {
                  const invoice = data[variableSizeListData.index];

                  return (
                    <MobileInvoicesTile
                      key={invoice.id}
                      invoice={invoice}
                      isPaid={isPaid}
                      isSubmitted={isSubmitted}
                      selectedInvoices={selectedInvoices}
                      onSelectInvoices={onSelectInvoices}
                      selectable={selectable}
                      {...variableSizeListData}
                    />
                  );
                }}
              </VariableSizeList>
            );
          }}
        </InfiniteLoader>

        {withInfiniteLoading && isLoadingMoreItems && (
          <InfiniteLoadingIndicatorWrapper>
            <InlineLoader />
          </InfiniteLoadingIndicatorWrapper>
        )}
      </Col>
    </>
  );
};

export default MobileInvoicesList;
